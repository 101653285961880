import { env } from "~/config"

export const reproio = (command: string, value: string, property?: Record<string, unknown>) => {
  if (env === "local") {
    console.log("reproio:", command, value, property)
    return
  }

  "reproio" in window && window.reproio(command, value, property)
}
