import React from "react"
import styled from "styled-components"

import { color } from "~/libs/style"

interface MicroroadProps {
  id: string
}

export const Microroad: React.FC<MicroroadProps> = ({ id }) => {
  return (
    <FixedWrapper>
      <div
        dangerouslySetInnerHTML={{
          __html: `
            <script type="text/javascript">
              var microadCompass = microadCompass || {};
              microadCompass.queue = microadCompass.queue || [];
              </script>
              <script type="text/javascript" charset="UTF-8" src="//j.microad.net/js/compass.js" onload="new microadCompass.AdInitializer().initialize();" async></script>
              <div id="${id}">
                  <script type="text/javascript">
                      microadCompass.queue.push({
                              "spot": "${id}"
                      });
                  </script>
              </div>
              <div id="ads-ADU-C9JC0I41"></div>
              <script>
      (function (w, d, s, i) {
                  var CREATIVE_ID = "${id}";
        var microadTag, countNumber = 0, isFindingMicroAdBanner = false;

        var findCreativeTagInterval = setInterval(findCreativeTag, 50);
        function findCreativeTag() {
          if (countNumber > 50) {
            clearInterval(findCreativeTagInterval);
            // add 2 following lines for Passback
            loadAdunit(microadTag.id);
            return;
          }
          if (isFindingMicroAdBanner) return;
          isFindingMicroAdBanner = true;
          var divTags = document.getElementsByTagName('div');
          countNumber = countNumber + 1;
          if (divTags && divTags.length) {
            for (var i = 0; i < divTags.length; i++) {
              var divTag = divTags[i];
              if (divTag && divTag.id && divTag.id.indexOf(CREATIVE_ID) > -1) {
                microadTag = divTag;
                microadTag.style.visibility = "hidden";
                if (microadTag && microadTag.getElementsByTagName('iframe') &&
                  microadTag.getElementsByTagName('iframe').length > 0) {
                  loadAdunit(microadTag.id);
                  clearInterval(findCreativeTagInterval);
                  break;
                }
              }
            }
          }
          isFindingMicroAdBanner = false;
        }
        function loadAdunit(microadBannerId) {
          w.adunitlength ? w.adunitlength.push('ADU-C9JC0I41') : w.adunitlength=['ADU-C9JC0I41']; var f=d.getElementsByTagName(s)[0], j=d.createElement(s);
          j.async=true; j.src='https://rise.enhance.co.jp/adu.js?id=' + i + '&matwName=matw_uuid' + '&clientTime=' + (new Date()).getTime() + '&maBannerId=' + microadBannerId;
          f.parentNode.insertBefore(j, f);
        }
      })(window, document, 'script', 'ADU-C9JC0I41');
    </script>
          `
        }}
      ></div>
    </FixedWrapper>
  )
}

const FixedWrapper = styled.div`
  > div {
    background-color: ${color.white};
    margin: 0px auto;
    padding: 0px;
    text-align: center;
    overflow: hidden;
    z-index: 6000000;
    position: fixed;
    bottom: 1px;
    width: 100%;
    > div:first-child {
      display: flex;
      justify-content: center;
    }
  }
`
