import "intersection-observer"

import React, { useEffect, useRef } from "react"
import styled, { keyframes } from "styled-components"

import { color, mixin } from "~/libs/style"

import { AdElement } from "./AdElement2"
type Props = {
  id: number
}

type PropsGoogle = {
  id: string
  url: string | null
  size: string | null
}

export const AdFixed: React.FC<PropsGoogle> = ({ id, url, size }) => {
  return (
    <FixedWrapper>
      <AdElement id={id} url={url} size={size} styleCustom={null} />
    </FixedWrapper>
  )
}

export const AdRectangle: React.FC<PropsGoogle> = ({ id, url, size }) => {
  return (
    <Wrapper>
      <AdElement id={id} url={url} size={size} styleCustom={null} />
    </Wrapper>
  )
}
export const AdElementCustomParent: React.FC<PropsGoogle> = ({ id, url, size }) => {
  return <AdElement id={id} url={url} size={size} styleCustom={null} />
}

export const AdBanner: React.FC<PropsGoogle> = ({ id, url, size }) => {
  return (
    <TopBanner className="ad-banner">
      <AdElement id={id} url={url} size={size} styleCustom={null} />
    </TopBanner>
  )
}

export const AdInfeed: React.FC<Props & { targetId: string }> = ({ id, targetId }) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const { current } = ref
    const adgScriptLoader = document.createElement("script")
    adgScriptLoader.async = true
    adgScriptLoader.src = `//i.socdm.com/sdk/js/adg-script-loader.js?id=${id}&targetID=${targetId}&displayid=3&adType=INFEED&async=true&tagver=2.0.0"`
    const observer = new IntersectionObserver(
      (entries, observer) => {
        if (entries.some((entry) => entry.isIntersecting)) {
          current?.appendChild(adgScriptLoader)
          observer.disconnect()
        }
      },
      {
        rootMargin: "200px"
      }
    )
    current && observer.observe(current)
    return () => {
      const target = document.getElementById(targetId)
      target?.parentNode?.removeChild(target)
      observer.disconnect()
    }
  }, [id, targetId])

  return (
    <InfeedWrapper>
      <LoadingLeft />
      <Loading />
      <LoadingRight />
      <Infeed ref={ref} />
    </InfeedWrapper>
  )
}

const TopBanner = styled.div`
  text-align: center;
  overflow: hidden;
  display: flex;
  padding: 10px 0px;
  justify-content: center;
  ${mixin.shadow}
  position: relative;
`

const InfeedWrapper = styled.div`
  position: relative;
  height: 90px;
`

const dotFlashing = keyframes`
  0% {
    opacity: 0.3;
  }
  30% {
    opacity: 1;
  }
  60%,
  100% {
    opacity: 0.3;
  }
`

const Loading = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 10px;
  height: 10px;
  margin: auto;
  background-color: ${color.orchidGray};
  border-radius: 5px;
  opacity: 0.3;
  animation: ${dotFlashing} 1.5s infinite linear;
  animation-delay: 0.3s;
`

const LoadingLeft = styled(Loading)`
  left: -30px;
  animation-delay: 0s;
`

const LoadingRight = styled(Loading)`
  left: 30px;
  animation-delay: 0.6s;
`

const Infeed = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
  & > div {
    background: #fff;
  }
`

const Wrapper = styled.div`
  ${mixin.maxWidth}
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding-bottom: 20px;
  background-color: ${color.bgMain};
`

const FixedWrapper = styled.div`
  > div {
    background-color: ${color.white};
    margin: 0px auto;
    padding: 0px;
    text-align: center;
    overflow: hidden;
    z-index: 6000000;
    position: fixed;
    bottom: 1px;
    width: 100%;
    > div:first-child {
      display: flex;
      justify-content: center;
    }
  }
`
