import { env } from "~/config"

export const trEvent = (category: string, action: string, label = "") => {
  if ("trEvent" in window) {
    window.trEvent(null, category, action, label)
  }
  if (env === "local") {
    console.log("trEvent:", category, action, label)
  }
}

export const trEventBe = (element: HTMLElement, category: string, action: string, label = "", event: Event) => {
  if ("trEventBe" in window) {
    window.trEventBe(element, category, action, label, event)
  }
  if (env === "local") {
    console.log("trEventBe:", element, category, action, label, event)
  }
}

export const prefixNumber = (num: number) => {
  const convertToString = num.toString()
  return convertToString.padStart(2, "0")
}
