import React from "react"
import styled from "styled-components"

import { color, mixin } from "~/libs/style"

export const Note: React.FC = () => {
  return (
    <Wrapper>
      <Items>
        <Title>推奨環境</Title>
        <ItemGroup>
          <Label>イージーゲーム</Label>
          <TextContent>
            Android 8 以上（Chrome 120以上）
            <Br />
            iOS 17.1 以上（Safari 17以上）
          </TextContent>
        </ItemGroup>
        <ItemGroup>
          <Label>
            イージーゲーム <Br />
            powered by ゲソてん
          </Label>
          <TextContent>
            Android 最新版（Chrome 最新版）
            <Br />
            iOS 最新版（Safari 最新版）
          </TextContent>
        </ItemGroup>
        <ItemGroup>
          <TextContent>※iOSではゲームの履歴が正常に表示されない場合があります。</TextContent>
        </ItemGroup>
        <Title>注意事項</Title>
        <Item>・不安定な通信環境では正常に遊べない場合があります。</Item>
        <Item>・端末、OS、ブラウザのバージョンによっては正常に遊べない場合があります。</Item>
        <Item>・プライベートブラウザモードでは正常に遊べない場合があります。</Item>
        <Item>・広告ブロックを設定すると正常に遊べない場合があります。</Item>
        <Item>・ブラウザのCookieやキャッシュをクリアすると、得点などの記録が消去されます。</Item>
        <Item>
          ・iOS 13.4以上の環境では7日以上の遊ばない期間が発生すると、得点などの記録が消去される場合があります。
        </Item>
        <Item>・本サービスは日本国内専用です。</Item>
        <Item>・This service is for Japan only.</Item>
      </Items>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 20px 13px;
  background-color: ${color.bgMain};
`

const Items = styled.div`
  ${mixin.maxWidth}
  margin: 0 auto;
`

const Title = styled.span`
  margin-bottom: 23px;
  font-size: 16px;
  font-weight: 700;
  line-height: 23.2px;
  color: #3c3c3c;
  display: block;
`
const Item = styled.span`
  margin-bottom: 5px;
  font-size: 13px;
  line-height: 18.85px;
  display: block;
  padding: 0 10px;
`

const Br = styled.br``

const ItemGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
`

const Label = styled.span`
  display: block;
  font-size: 12px;
  font-weight: 400;
  line-height: 17.4px;
  width: 150px;
`

const TextContent = styled.span`
  flex: 1;
  font-size: 12px;
  font-weight: 400;
  line-height: 17.4px;
`
