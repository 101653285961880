import React, { createContext, useCallback, useContext } from "react"

import { trEventBe } from "~/libs/analytics"

const TrackCategoryContext = createContext<string | null>(null)

export const useTracking = () => {
  const category = useContext(TrackCategoryContext)
  const analyticsTrack = useCallback(
    (event, action: string, label = "") => {
      trEventBe(event.currentTarget, `【click】${category}画面`, action, label, event.nativeEvent)
    },
    [category]
  )
  return { category, analyticsTrack }
}

export const useTrackingNew = () => {
  const analyticsTrack = useCallback((event, category: string, action: string, label = "") => {
    trEventBe(event.currentTarget, category, action, label, event.nativeEvent)
  }, [])
  return { analyticsTrack }
}

export const TrackingProvider: React.FC<{ categoryProp: string }> = ({ categoryProp, children }) => {
  return <TrackCategoryContext.Provider value={categoryProp}>{children}</TrackCategoryContext.Provider>
}
