function get<T>(key: string) {
  const json = window.localStorage.getItem(key)
  if (!json) {
    return null
  }
  const data: T = JSON.parse(json)
  return data
}

function set<T>(key: string, value: T) {
  window.localStorage.setItem(key, JSON.stringify(value))
}

export const keys = {
  history: "habits_play_history",
  paidHistory: "habits_paid_history"
}

export const maxPlayHistorySize = 10

export function getPlayHistories(key: string = keys.history) {
  try {
    return get<number[]>(key) ?? []
  } catch (e) {
    console.error(e)
  }
  return []
}

export function addPlayHistory(gameId: number, key: string = keys.history) {
  try {
    const histories = [
      gameId,
      ...getPlayHistories(key)
        .filter((historyGameId) => historyGameId != gameId)
        .splice(0, maxPlayHistorySize - 1)
    ]
    set<number[]>(key, histories)
  } catch (e) {
    console.error(e)
  }
}

export function setPlayHistories(gameIds: number[], key: string = keys.history) {
  try {
    set<number[]>(key, gameIds)
  } catch (e) {
    console.error(e)
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function addPlayHistoryFreePage(gameId: number, domain: string, storageRef: any) {
  try {
    if (storageRef.current === null) return
    storageRef.current.contentWindow?.postMessage(gameId, `${domain + "localstorage"}`)
  } catch (e) {
    console.error(e)
  }
}
