import { useEffect } from "react"

import { Distribution, repro } from "~/config"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function useRepro(distribution: Distribution) {
  useEffect(() => {
    /*if (distribution === "app") {
      return
    }*/
    const reproSnippet = document.createElement("script")
    reproSnippet.id = "repro"
    /* eslint-disable max-len */
    reproSnippet.textContent = `
      !function(o,e,n){var r=[];if(window.reproio)console.info("Repro Web SDK was loaded more than once");else{window.reproio=function(){r.push(arguments)};var i=o.createElement(e),t=o.getElementsByTagName(e)[0];i.src="https://cdn.reproio.com/web/v2/repro-sdk.min.js",i.async=!0,i.crossOrigin="",i.onload=function(){window.reproio("setSnippetVersion","2.1"),r.forEach(function(o){window.reproio.apply(window.reproio,o)})},t.parentNode.insertBefore(i,t)}}(document,"script");
      `
    /* eslint-enable max-len */
    document.head.appendChild(reproSnippet)
    window.reproio("setup", repro.sdkToken)
    window.reproio("track", "PageView")
  }, [])
}
