import React, { createContext, useContext, useEffect, useState } from "react"

type ExperimentState = Array<{
  testId: string
  neta: string
  pattern?: string
}>

const ExperimentContext = createContext<ExperimentState>([])

type ExperimentProviderProps = {
  configExperiments: ExperimentState
}

export const ExperimentProvider: React.FC<ExperimentProviderProps> = ({ children, configExperiments }) => {
  const [experiments, setExperiments] = useState(
    configExperiments.map((configExperiment) => {
      return {
        ...configExperiment,
        pattern: "0"
      }
    })
  )

  useEffect(() => {
    let rafId = 0
    const detectOptimize = () => {
      if ("google_optimize" in window) {
        const newExperiments = experiments.map((experiment) => {
          const pattern = window.google_optimize.get(experiment.testId)
          return /[0-9]/.test(pattern)
            ? {
                ...experiment,
                pattern: `${pattern}`
              }
            : experiment
        })
        setExperiments(newExperiments)
        window.cancelAnimationFrame(rafId)
        return
      }
      rafId = window.requestAnimationFrame(detectOptimize)
    }
    rafId = window.requestAnimationFrame(detectOptimize)
  }, [])

  return <ExperimentContext.Provider value={experiments}>{children}</ExperimentContext.Provider>
}

type VariantProps = {
  neta: string
  pattern: string
}

export const Variant: React.FC<VariantProps> = ({ children, neta, pattern }) => {
  const experiments = useContext(ExperimentContext)
  if (pattern === "0" && !experiments.some((experiment) => experiment.neta === neta)) {
    return <>{children}</>
  }
  const allocated = experiments.some((experiment) => {
    return experiment.neta === neta && experiment.pattern === pattern
  })
  return allocated ? <> {children} </> : null
}
